import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useFlexSearch } from "react-use-flexsearch";
import { Formik, Form, Field } from "formik";
import Layout from "../layouts";
import Seo from "../components/seo";
import {
  BreadCrumb,
  FlexWrap,
  RightWrap,
  LeftWrap,
  Section,
  SectionPageTitle,
  SectionDescription,
  SubTitle,
} from "../components/Section";
import BlogCard from "../components/BlogCard";
import LineArrowRight from "../components/Icons/LineArrowRight";

const Card = styled.div`
  width: 100%;
  margin: 45px 0 90px;
  .image-wrap {
    height: 500px;
  }

  @media (max-width: 480px) {
    margin: 40px 0;
  }
`;

const SideBar = styled.div`
  width: 350px;
  margin-left: 50px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 40px 25px;

  input {
    width: 100%;
    border: 1px solid #d0d0d0;
    padding: 15px 15px 18px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    line-height: 1;
    margin-bottom: 30px;
  }
  form {
    button {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-left: 0;
  }
`;
const Category = styled.div`
  span {
    color: #e43d3c;
    margin-right: 10px;
  }
`;

const CategoryItem = styled.div`
  cursor: pointer;

  color: #938888;
  &:hover {
    color: #e43d3c;
  }
  &.active {
    color: #e43d3c;
  }
`;

const BlogWrap = styled.div`
  margin-bottom: 30px;

  .title {
    font-size: 21px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .feature {
    color: #e43d3c;
    font-family: Eina03-SemiBold;
  }
  .gatsby-image-wrapper {
    border-radius: 6px;
    margin: 20px 0;
  }
`;

const MoreCategory = styled.div`
  margin: 10px 0 20px;
  color: #e43d3c;
  display: flex;
  align-items: center;

  svg {
    stroke: #e43d3c;
    width: 15px;
    margin-left: 5px;
  }
  &:hover {
    cursor: pointer;
    svg {
      margin-left: 8px;
    }
  }
`;

const SearchForm = styled.div`
  position: relative;
  z-index: 10;
  margin-bottom: 20px;

  input {
    width: 100%;
    border: 1px solid #d0d0d0;
    padding: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    line-height: 1;
  }
`;

const SearchResults = styled.div`
  position: absolute;
  display: ${(props) => props.display};
  padding: 20px;
  width: 300px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  background: #ffffff;
  right: 0;
  top: 55px;
  z-index: 20;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  li {
    list-style: none;

    a {
      color: #000000;

      &:hover {
        text-decoration: underline;
        color: #e43d3c;
      }
    }
  }
`;

const Blog = ({ location, data }) => {
  const {
    allContentfulBlog: { edges: blogs },
    allContentfulBlogCategory: { edges: categories },
  } = data;
  const [categoryLimit, setCategoryLimit] = useState(3);

  const [isCurrentCategory, setIsCurrentCategory] = useState(
    location?.state?.category || ""
  );
  const [query, setQuery] = useState(null);
  const index = data.localSearchPages.index;
  const store = data.localSearchPages.store;

  const results = useFlexSearch(query, index, store);

  let showResults;
  if (!isCurrentCategory) showResults = [...blogs];
  else {
    showResults = blogs.filter((blog) => {
      if (blog.node.category.name === isCurrentCategory) return true;
      return false;
    });
  }

  return (
    <Layout footerCta location={location}>
      <Seo
        title="Blogs and Articles - Buildings And More"
        description="We have covered various topics about outdoor buildings in our blog. Check it out now!"
      />
      <BreadCrumb position="relative">
        <div className="container">
          <Link to="/">Home</Link>
          <span>&nbsp;/&nbsp;</span>
          <span>Blog</span>
        </div>
      </BreadCrumb>
      <Section bgColor="#F7F5F5">
        <div className="container">
          <FlexWrap>
            <LeftWrap width="calc(100% - 400px)">
              <SectionPageTitle textAlign="left">
                Buildings And More Blogs
              </SectionPageTitle>
              <SectionDescription textAlign="left">
                <p>
                  Building And More wants to make sure that you get an accurate
                  and affordable quote fast. Please contact us at{" "}
                  <a href="tel:3867556449">(386) 755-6449</a> or use the form
                  for pricing and additional information on the products you are
                  interested in. Our buildings are available for viewing any
                  time during daylight hours.
                </p>
              </SectionDescription>
              {showResults.map((item, i) => (
                <Card key={i}>
                  <BlogCard data={item.node} />
                </Card>
              ))}
            </LeftWrap>
            <RightWrap width="400px">
              <SideBar>
                <SearchForm>
                  <Formik
                    initialValues={{ query: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      setQuery(values.query);
                      setSubmitting(false);
                    }}
                  >
                    <Form>
                      <Field name="query" placeholder="Search Blog ..." />
                    </Form>
                  </Formik>
                  <SearchResults display={query !== null ? "block" : "none"}>
                    {results?.length > 0 ? (
                      results.map((result) => (
                        <li>
                          <Link to={result.path}>{result.title}</Link>
                        </li>
                      ))
                    ) : (
                      <p>There is no blogs with that search key</p>
                    )}
                  </SearchResults>
                </SearchForm>
                <SubTitle mt="0" mb="15px" textAlign="left">
                  Categories
                </SubTitle>
                {categories.map((item, i) => {
                  if (i < categoryLimit) {
                    return (
                      <Category>
                        <CategoryItem
                          className={
                            item.node.name === isCurrentCategory ? "active" : ""
                          }
                          key={i}
                          onClick={() => setIsCurrentCategory(item.node.name)}
                        >
                          <span>-</span> {item.node.name}
                        </CategoryItem>
                      </Category>
                    );
                  }
                  return null;
                })}
                {categoryLimit < categories.length && (
                  <MoreCategory
                    onClick={() => setCategoryLimit(categoryLimit + 3)}
                  >
                    More categories <LineArrowRight />
                  </MoreCategory>
                )}
                <SubTitle mt="15px" mb="15px" textAlign="left">
                  Recent Posts
                </SubTitle>
                {blogs.map(
                  (item, i) =>
                    i < 3 && (
                      <BlogWrap key={i}>
                        <Link to={`/blog${item.node.url}`}>
                          <h3 class="title">{item.node.title}</h3>
                        </Link>
                        <FlexWrap alignItems="center" justifyContent="center">
                          <LeftWrap width="100%">
                            <Link to={`/blog${item.node.url}`}>
                              <GatsbyImage
                                image={getImage(
                                  item.node.image.gatsbyImageData
                                )}
                                alt={item.node.title}
                              />
                            </Link>
                          </LeftWrap>
                        </FlexWrap>
                      </BlogWrap>
                    )
                )}
              </SideBar>
            </RightWrap>
          </FlexWrap>
        </div>
      </Section>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query BlogPageQuery {
    allContentfulBlog(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 80)
          }
          author {
            name
          }
          createdAt(formatString: "MMM DD, yy")
          url
          category {
            name
          }
        }
      }
    }
    allContentfulBlogCategory {
      edges {
        node {
          name
          url
        }
      }
    }
    localSearchPages {
      index
      store
    }
  }
`;
