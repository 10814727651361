import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import CalendarIcon from "../Icons/CalendarIcon"
import UserIcon from "../Icons/UserIcon"

const CardWrap = styled.div`
	background: #ffffff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
	border-radius: 5px;

	.image-wrap {
		width: 100%;
		height: 240px;

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
			}
		}
	}
	.text-wrap {
		position: relative;
		padding: 20px 35px 40px;
		height: 330px;

		.heading {
			display: flex;
			align-items: center;
			p {
				color: #E43D3C;
				margin: 0 20px 0 10px;
			}
		}
		.title {
			margin: 20px 0;
			text-align: left;
			font-size: 24px;
			line-height: 34px;
			font-family: "Eina03-Bold";
			height: 68px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.description {
			text-align: left;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}

		.read-more {
			position: absolute;
			bottom: 35px;
			left: 35px;
			text-transform: uppercase;
			color: #E43D3C;
		}
	}

	@media(max-width: 1200px) {
		.text-wrap {
			padding: 20px;
			.read-more {
				left: 20px;
			}
		}
	}
	@media(max-width: 991px) {
		.text-wrap {
			padding: 20px 35px 40px;
			.read-more {
				left: 35px;
			}
		}
	}
	@media(max-width: 480px) {
		.text-wrap {
			padding: 20px 20px 30px;
			.read-more {
				left: 20px;
			}
		}
	}
`

const BlogCard = ({ data }) => (
	<CardWrap>
		<Link to={`/blog${data.url}`} className="image-wrap">
			<GatsbyImage image={getImage(data.image.gatsbyImageData)} alt={data.title} />
		</Link>
		<div className="text-wrap">
			<div className="heading">
				<CalendarIcon />
				<p>{data.createdAt}</p>
				<UserIcon />
				<p>{data.author.name}</p>
			</div>
			<div className="title">
				{data.title}
			</div>
			<div className="description" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
			<Link to={`/blog${data.url}`} className="read-more">
				READ MORE…
			</Link>
		</div>
	</CardWrap>
)

export default BlogCard